<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고구분 -->
          <c-select
            type="search"
            :disabled="typeDisabled"
            codeGroupCd="IIM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentTypeCd"
            label="사고구분"
            v-model="searchParam.accidentTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고종류 -->
          <c-multi-select
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCdList"
            label="LBL0002301"
            v-model="searchParam.accidentKindCdList"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공정 -->
          <c-process
            :editable="editable"
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="IIM_STATUS_PROCESS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="accidentStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.accidentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생부서 -->
          <c-dept
            type="search"
            :isFirstValue="false"
            name="occurrenceDeptCd"
            label="LBL0002302"
            v-model="searchParam.occurrenceDeptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생기간 -->
          <c-datepicker
            :range="true"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            label="LBL0002303"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="iimAccidentId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "accident-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          accidentTypeCd: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentTypeName",
            field: "accidentTypeName",
            style: 'width:100px',
            label: "재해유형",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentName",
            field: "accidentName",
            label: "재해명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            style: 'width:130px',
            label: "재해일시",
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceLocation",
            field: "occurrenceLocation",
            style: 'width:130px',
            label: "재해장소",
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDeptName",
            field: "occurrenceDeptName",
            // 발생부서
            label: "LBL0002302",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentNo",
            field: "accidentNo",
            label: "사고번호(품번)",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "processName",
            field: "processName",
            // 공정
            label: "LBLPROCESS",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentStatusName",
            field: "accidentStatusName",
            style: 'width:100px',
            // 진행상태
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentUser",
            field: "accidentUser",
            label: '사고자 정보',
            child: [
              {
                name: "accidentDeptName",
                field: "accidentDeptName",
                style: 'width:80px',
                label: "부서",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentUserName",
                field: "accidentUserName",
                style: 'width:80px',
                label: "이름",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentEnterDate",
                field: "accidentEnterDate",
                style: 'width:80px',
                label: "입사일자",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentBirthDate",
                field: "accidentBirthDate",
                style: 'width:80px',
                label: "생년월일",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentMobileNo",
                field: "accidentMobileNo",
                style: 'width:100px',
                label: "전화번호",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentEmpNo",
                field: "accidentEmpNo",
                style: 'width:80px',
                label: "사번",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentSpotName",
                field: "accidentSpotName",
                style: 'width:80px',
                label: "직위",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentAge",
                field: "accidentAge",
                style: 'width:80px',
                label: "연령",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentSexName",
                field: "accidentSexName",
                style: 'width:80px',
                label: "성별",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentLongevityCount",
                field: "accidentLongevityCount",
                style: 'width:80px',
                label: "근속년수",
                align: "center",
                sortable: true,
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        occurrenceDeptCd: '',
        accidentKindCdList: [],
        accidentStatusCd: null,
        accidentTypeCd: null,
        processCd: '',
        period: [],
      },
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      typeDisabled: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // role setting
      if (this.popupParam.accidentTypeCd) {
        this.searchParam.accidentTypeCd = this.popupParam.accidentTypeCd;
        this.typeDisabled = true
      }
      this.listUrl = selectConfig.sop.iim.accident.process.list.url;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      this.popupOptions.target = () => import(`${"./processAccidentDetail.vue"}`);
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
        stepCd: row ? row.accidentStatusCd : '',
      };
      this.popupOptions.title = "사고 상세"; 
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
